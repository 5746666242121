import React from 'react';
import { Bar } from '@nivo/bar';

// const data = require('../data.json');

const Chart = ({ data, score }) => (
  <div>
    <Bar
      width={1450}
      height={400}
      margin={{ top: 40, right: 60, bottom: 60, left: 60 }}
      data={data}
      indexBy="score"
      keys={['인원 수']}
      enableGridX="true"
      enableGridY="true"
      axisBottom={{
        legend: '점수',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        legend: '인원',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      annotations={[
        {
          type: 'circle',
          match: { key: '인원 수.' + score },
          noteX: 40,
          noteY: 40,
          offset: 1,
          note: '나의 위치',
          noteTextOffset: -3,
          noteWidth: 5,
          size: 40,
        },
      ]}
    />
  </div>
);

export default Chart;
