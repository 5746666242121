import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { refreshProfile } from './refreshProfile';

const UserContext = createContext(null);
const UserUpdateContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => ({ isLoggedIn: null }));
  useEffect(() => {
    refreshProfile(setUser);
  }, []);

  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={setUser}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
};
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useUser = () => useContext(UserContext);
const useUserUpdate = () => useContext(UserUpdateContext);

export { UserProvider, useUser, useUserUpdate };
