import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useUser, useUserUpdate } from '../context/UserContext';
import { refreshProfile } from '../context/UserContext/refreshProfile';
import { doLogout } from '../services/api';

import Copyright from './Copyright';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

function createData(id, name, scoreA, perfectA, scoreB, perfectB, openDate) {
  return { id, name, scoreA, perfectA, scoreB, perfectB, openDate };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  tableHead: {
    fontSize: 16,
    fontWeight: 600,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

const Template = (Contents) => {
  const setUser = useUserUpdate();
  const logoutHandler = () => {
    doLogout()
      .then((res) => {
        setUser({ isLoggedIn: false });
        window.location.href = '/mogo/login';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const page = (props) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar color="primary" position="absolute">
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <Link component={RouterLink} to="/mogo/index" color="inherit">
                임고파워 모의고사 배수예측 시스템
              </Link>
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="default"
              className={classes.button}
            >
              <Link component={RouterLink} to="/mogo/pw">
                비밀번호 변경
              </Link>
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              onClick={logoutHandler}
            >
              로그아웃
            </Button>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Contents {...props} />
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    );
  };

  return page;
};

export default Template;
