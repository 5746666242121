import { useState } from 'react';

export default (initialValue) => {
  const [items, setItems] = useState(initialValue.items);

  return {
    items,
    setItemsWithFunc: (func) => {
      setItems((prevItems) => func(prevItems));
    },
  };
};
