import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Template from '../components/Template';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Chart from 'mogo/components/Chart';
import { InputLabel } from '@material-ui/core';
import RateSlider from '../components/RateSlider';
import { getResult } from '../services/api';
import { Redirect, useParams } from 'react-router-dom';
import { useUser } from 'mogo/context/UserContext';

// const data = require('../data.json');

const lastYearRate = {
  서울: 3.38,
  인천: 1.96,
  경기: 2.16,
  대전: 4.22,
  세종: 3.59,
  충북: 1.79,
  충남: 1.87,
  부산: 2.04,
  울산: 1.4,
  대구: 2.14,
  경북: 1.41,
  경남: 1.66,
  광주: 7.56,
  전북: 1.65,
  전남: 1.49,
  강원: 2.53,
  제주: 1.57,
  전국: 2.03,
  경기연천: 2.5,
  경기포천: 2.7,
  충남서부: 2.0,
  전남지역: 1.25,
  도교지역: 1.05,
  전남도서: 1.0,
  도교도서: 0.33,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    width: '20%',
  },
  divSpace: {
    marginBottom: theme.spacing(2),
  },
}));

const renderRegionSelectForm = (classes, region, handleChange) => {
  return (
    <div className={classes.divSpace}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="region-select">지역</InputLabel>
        <Select
          labelId="region-select-label"
          id="region-select"
          value={region}
          onChange={handleChange}
          label="지역"
        >
          <MenuItem value={'전국'}>전국</MenuItem>
          <MenuItem value={'서울'}>서울</MenuItem>
          <MenuItem value={'인천'}>인천</MenuItem>
          <MenuItem value={'경기'}>경기</MenuItem>
          <MenuItem value={'경기연천'}>경기연천</MenuItem>
          <MenuItem value={'경기포천'}>경기포천</MenuItem>
          <MenuItem value={'대전'}>대전</MenuItem>
          <MenuItem value={'세종'}>세종</MenuItem>
          <MenuItem value={'충북'}>충북</MenuItem>
          <MenuItem value={'충남'}>충남</MenuItem>
          <MenuItem value={'충남서부'}>충남서부</MenuItem>
          <MenuItem value={'부산'}>부산</MenuItem>
          <MenuItem value={'울산'}>울산</MenuItem>
          <MenuItem value={'대구'}>대구</MenuItem>
          <MenuItem value={'경북'}>경북</MenuItem>
          <MenuItem value={'경남'}>경남</MenuItem>
          <MenuItem value={'광주'}>광주</MenuItem>
          <MenuItem value={'전북'}>전북</MenuItem>
          <MenuItem value={'전남'}>전남</MenuItem>
          <MenuItem value={'전남지역'}>전남지역</MenuItem>
          <MenuItem value={'도교지역'}>도교지역</MenuItem>
          <MenuItem value={'전남도서'}>전남도서</MenuItem>
          <MenuItem value={'도교도서'}>도교도서</MenuItem>
          <MenuItem value={'강원'}>강원</MenuItem>
          <MenuItem value={'제주'}>제주</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const renderScore = (scoreA, scoreB, classes) => (
  <div className={classes.divSpace}>
    <Typography variant="h5">
      A형:{' '}
      <b>
        {scoreA} / {41}
      </b>
      , B형:{' '}
      <b>
        {scoreB} / {39}
      </b>
      , 총점:{' '}
      <b>
        {scoreA + scoreB} / {80}
      </b>
    </Typography>
  </div>
);

const renderRanking = (classes, allCnt, tieCnt, rank, region) => (
  <div className={classes.divSpace}>
    <Typography variant="h5">
      <b>{region}</b> 지역 응시자 <b>{allCnt}</b>명 중, <b>{rank}</b>등입니다.
      (상위 <b>{((rank / allCnt) * 100).toFixed(2)}</b>%, 동점자 <b>{tieCnt}</b>
      명)
    </Typography>
  </div>
);

const renderMultiplier = (
  allCnt,
  rank,
  rate,
  cutLine,
  acceptCnt15,
  cutLine15,
  classes,
) => (
  <div className={classes.divSpace}>
    <Typography variant="h5">
      1배수 기준 합격자 수는 <b>{(allCnt / rate).toFixed(0)}</b>명(커트라인:{' '}
      <b>{cutLine}</b>점),
      <br /> 1.5배수 기준 합격자 수는 <b>{acceptCnt15}</b>
      명(커트라인: <b>{cutLine15}</b>점)이며,
      <br /> 본인은 현재 <b>{(rank / (allCnt / rate).toFixed(0)).toFixed(2)}</b>
      배수입니다.
    </Typography>
  </div>
);
const getCutLine = (data, acceptCnt) => {
  let k = 0;
  if (acceptCnt <= 0) {
    return 0;
  }
  for (let i = data.length - 1; i >= 0; i--) {
    k += data[i]['인원 수'];
    if (k >= acceptCnt) {
      return data[i].score;
    }
  }

  return 0;
};
const WrappedPage = ({ testId }) => {
  const classes = useStyles();
  const [region, setRegion] = useState('전국');
  const [rate, setRate] = useState(lastYearRate[region]);
  const [results, setResults] = useState([]);
  const [data, setData] = useState([]);
  const user = useUser();
  const scoreA = user.scores?.[testId]?.scoreA;
  const scoreB = user.scores?.[testId]?.scoreB;
  const score = scoreA + scoreB;
  const allCnt = data.reduce((acc, curr) => acc + curr['인원 수'], 0);
  const rank = data.reduce(
    (acc, curr) => (curr.score > score ? acc + curr['인원 수'] : acc),
    1,
  );
  const tieCnt = data.reduce(
    (acc, curr) => (curr.score === score ? acc + curr['인원 수'] : acc),
    0,
  );
  const acceptCnt = (allCnt / rate).toFixed(0);
  const acceptCnt15 = ((allCnt / rate) * 1.5).toFixed(0);
  const cutLine = getCutLine(data, acceptCnt);
  const cutLine15 = getCutLine(data, acceptCnt15);

  const handleChange = (event) => {
    setData(
      results[event.target.value]?.map((item) => ({
        ...item,
        '인원 수': item.count,
      })) ?? [],
    );
    setRegion(event.target.value);
    setRate(lastYearRate[event.target.value]);
  };

  useEffect(() => {
    getResult(testId).then((res) => {
      setResults(res.data);
      setData(
        res.data[region]?.map((item) => ({
          ...item,
          '인원 수': item.count,
        })) ?? [],
      );
    });
  }, []);

  return (
    <div>
      <Grid item lg={12}>
        <Paper className={classes.paper}>
          {renderRegionSelectForm(classes, region, handleChange)}
          <Chart data={data} score={score} />
          {renderScore(scoreA, scoreB, classes)}
          {renderRanking(classes, allCnt, tieCnt, rank, region)}
          <RateSlider
            rate={rate}
            setRate={setRate}
            lastYearRate={lastYearRate[region]}
          />
          {renderMultiplier(
            allCnt,
            rank,
            rate,
            cutLine,
            acceptCnt15,
            cutLine15,
            classes,
          )}
        </Paper>
      </Grid>
    </div>
  );
};

const MogoResult = () => {
  const { testId } = useParams();

  return Template(() => WrappedPage({ testId }))();
};

export default MogoResult;
