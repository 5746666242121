import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../components/Copyright';
import { useUser, useUserUpdate, UserProvider } from '../context/UserContext';
import { refreshProfile } from '../context/UserContext/refreshProfile';
import { Redirect } from 'react-router-dom';
import { doLogin } from '../services/api';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const MogoLogin = () => {
  const user = useUser();
  const setUser = useUserUpdate();
  const classes = useStyles();
  const [serialId, setSerialId] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    refreshProfile(setUser);
  }, []);

  const loginHandler = (e) => {
    e.preventDefault();
    doLogin(serialId, password)
      .then((res) => {
        if (!res.data.num) {
          setErrorMessage('로그인에 실패하였습니다.');
        } else {
          refreshProfile(setUser);
        }
      })
      .catch((err) => {
        setErrorMessage('로그인에 실패하였습니다.');
      });
  };

  if (user.isLoggedIn) {
    return <Redirect to="/mogo/index" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          임고파워 모의고사 배수예측 시스템
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="serial"
            label="식별 문자열"
            value={serialId}
            onChange={(e) => setSerialId(e.target.value)}
            name="serial"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="비밀번호(초기값: 0000)"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            type="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={loginHandler}
          >
            로그인
          </Button>
        </div>
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default MogoLogin;
