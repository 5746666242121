import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  List, ListItem, ListItemText, Divider,
} from '@material-ui/core';
import { lightGreen, red } from '@material-ui/core/colors';
import useListState from './useListState';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1.5,
    color: theme.palette.text.secondary,
  },
  chunking: {
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1,
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(1),
  },
  none: {
    padding: 0,
  },
  accept: {
    backgroundColor: lightGreen[100],
    padding: 0,
  },
  reject: {
    backgroundColor: red[100],
    padding: 0,
  },
  answers: {
    fontWeight: 700,
    fontSize: theme.typography.fontSize * 1.5,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  actions: {
    fontWeight: 600,
    fontSize: theme.typography.fontSize * 1.2,
  },
}));

const JudgeExcelView = ({ subject, answers, actions = null }) => {
  const classes = useStyles();
  const initItems = answers.map((_, id) => ({ id, text: '', status: 'none', action: '' }));
  const initProps = {
    items: initItems,
    answers,
  };
  const {
    items, setItemsWithFunc,
  } = useListState(initProps);

  const handleChange = (e, id) => {
    const text = e.target.value;

    setItemsWithFunc((prevItems) => (
      prevItems.map((prevItem) => (
        prevItem.id === id ? {
          ...prevItem,
          text,
        } : prevItem))
    ));
  };
  const handleChangeAction = (e, id) => {
    const action = e.target.value;

    setItemsWithFunc((prevItems) => (
      prevItems.map((prevItem) => (
        prevItem.id === id ? {
          ...prevItem,
          action,
        } : prevItem))
    ));
  };
  const handleRemoveAnswers = () => {
    setItemsWithFunc((prevItems) => (
      prevItems.map((prevItem) => ({
        ...prevItem,
        status: 'none',
      }))));
  };
  const handleResetAll = () => {
    setItemsWithFunc(() => initItems);
  };

  const handleSubmit = () => {
    setItemsWithFunc((prevItems) => (
      prevItems.map((prevItem) => {
        const text = prevItem.text ? prevItem.text : '';
        const trimmedText = text.trim();
        const preProcessedText = trimmedText.replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
        const preProcessedAnswer = answers[prevItem.id].trim().replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
        if (preProcessedText === preProcessedAnswer) {
          return {
            ...prevItem,
            text: trimmedText,
            status: 'accept',
          };
        }

        return {
          ...prevItem,
          text: trimmedText,
          status: 'reject',
        };
      })
    ));
  };

  const renderAnswers = (id) => (
    <>
      <p className={classes.answers}>{answers[id]}</p>
      {actions !== null && (
      <TextField
        inputProps={{ className: classes.actions }}
        fullWidth
        multiline
        disabled
        rowsMax="10"
        color="primary"
        variant="outlined"
        value={`- ${actions[id].join('\n- ')}`}
        size="small"
        margin="normal"
      />
      )}
    </>
  );

  return (
    <Paper className={classes.paper} elevation={3}>
      <Typography variant="h4" gutterBottom>
        {subject}
      </Typography>

      <TextField
        fullWidth
        multiline
        rowsMax="4"
        color="primary"
        variant="outlined"
        placeholder="이곳에 청킹 입력"
        size="small"
        margin="normal"
      />

      <List>
        {items.map((item, id) => {
          const key = id.toString() + subject;

          return (
            <ListItem key={key} className={classes[item.status]}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    onChange={(e) => handleChange(e, id)}
                    fullWidth
                    multiline
                    rowsMax="2"
                    color="primary"
                    placeholder="이곳에 정답 입력"
                    value={item.text}
                    margin="normal"
                  />
                  {actions !== null && (
                    <TextField
                      onChange={(e) => handleChangeAction(e, id)}
                      fullWidth
                      multiline
                      rowsMax="10"
                      color="primary"
                      variant="outlined"
                      placeholder="이곳에 활동 입력"
                      size="small"
                      value={item.action}
                      margin="normal"
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {
                    item.status !== 'none' && renderAnswers(id)
                  }
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>

      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="medium"
        type="submit"
        onClick={handleSubmit}
      >
        제출
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="default"
        size="medium"
        type="submit"
        onClick={handleRemoveAnswers}
      >
        답 가리기
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        size="medium"
        type="submit"
        onClick={handleResetAll}
      >
        모두 지우기
      </Button>
    </Paper>
  );
};

export default JudgeExcelView;
