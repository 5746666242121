import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

class MyApp extends Component {
  state = {
    date: new Date(),
  }

  componentDidMount() {
    setInterval(
      () => this.setState({ date: new Date() }),
      1000
    );
  }

  render() {
    return (
      <Typography>
        {this.state.date.toLocaleDateString('ko-KR') + ' ' + this.state.date.toLocaleTimeString('ko-KR')}
      </Typography>
    );
  }
}

export default MyApp;
