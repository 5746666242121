import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import { lightGreen, red, blue, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  none: {
  },
  accept: {
    backgroundColor: lightGreen[100],
  },
  yellow: {
    backgroundColor: yellow[100],
  },
  blue: {
    backgroundColor: blue[100],
  },
  reject: {
    // backgroundColor: red[100],
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    border: '2px solid black',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
  },
  body: {
    padding: theme.spacing(1),
    fontSize: 14,
    border: '2px solid black',
  },
}))(TableCell);


function InbodyTableWithRT({
  columns, data, rowSpan, isBlank, updateMyData, defaultCell, isEdit=false,
}) {
  const classes = useStyles();
  const defaultColumn = {
    Cell: defaultCell,
  };
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    defaultColumn,
    updateMyData,
  });
  const [cursor, setCursor] = useState(() => ({ i: 0, j: 0 }));

  const onGlobalKeyDown = (e) => {
  };

  useEffect(() => {
    document.removeEventListener('keydown', onGlobalKeyDown, false);
    document.addEventListener('keydown', onGlobalKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onGlobalKeyDown, false);
      // document.removeEventListener('click', onGlobalClick, false);
    };
  }, []);

  // Render the UI for your table
  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledTableCell {...column.getHeaderProps()}>{column.render('Header')}</StyledTableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                if (cell.value === undefined) {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={j} />
                  );
                }
                const bgColor = (cell.value.status === 'none' && cell.value.meta.backgroundColor)
                  ? cell.value.meta.backgroundColor : cell.value.status;
                return (
                  <StyledTableCell
                    className={classes[bgColor]}
                    rowSpan={rowSpan[i][j]}
                    {...cell.getCellProps()}
                  >
                    {!isBlank[i][j] && cell.render('Cell', cursor)}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default InbodyTableWithRT;
