import React from 'react';
import {
  Grid, TextField, Typography, Button, makeStyles,
} from '@material-ui/core';

import EditableCell from './EditableCell';
import InbodyTableWithRT from './InbodyTableWithRT';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const makeData = (body) => {
  const rowSpan = [];
  const isBlank = [];
  const meta = [];
  let max = 0;
  const data = body.map((row, i) => {
    const dataRow = {};
    const rowSpanRow = {};
    const isBlankRow = {};
    const metaRow = {};
    row.forEach((col, j) => {
      if (j > max) {
        max = j;
      }
      const p = j.toString();
      if (col.text !== '' && col.text !== undefined) {
        isBlankRow[p] = col.text === '1';
        dataRow[p] = col.text;
        rowSpanRow[p] = col.rowSpan;
        metaRow[p] = col;
      }
    });

    isBlank.push(isBlankRow);
    rowSpan.push(rowSpanRow);
    meta.push(metaRow);
    return dataRow;
  });

  return [data, rowSpan, isBlank, meta, max.toString()];
};

const defaultCell = ({ cell }) => (
  <textarea
    disabled
    rows={2}
    style={{
      padding: 0,
      margin: 0,
      border: 0,
      fontSize: cell.value.meta.isBold ? 20 : 16,
      fontWeight: cell.value.meta.isBold ? 800 : 600,
      width: '100%',
      height: '100%',
      resize: 'none',
    }}
    value={cell.value.text}
  />
);

const InbodyTable = ({ table }) => {
  const classes = useStyles();
  const columns = React.useMemo(() => table.head, []);
  const [
    answers, rowSpan, isBlank, meta, colLenStr,
  ] = React.useMemo(() => makeData(table.body), []);
  const answerGroup = React.useMemo(() => table.answerGroup, []);
  const [data, setData] = React.useState(() => answers.map(
    (row, i) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = {
          text: '', status: 'none', meta: meta[i][key], focus: false,
        };
      });

      return newRow;
    },
  ));
  const [solData, setSolData] = React.useState(() => answers.map(
    (row, i) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = { text: '???', status: 'none', meta: meta[i][key] };
      });

      return newRow;
    },
  ));

  const updateMyData = (rowIndex, columnId, text) => {
    setData((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: {
            ...row[columnId],
            text,
          },
        };
      }
      return row;
    }));
  };

  const isMatch = (a, b) => {
    const processedA = a.trim().replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
    const processedB = b.trim().replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
    if (processedA === '1' || processedB === '1') {
      return false;
    }

    return processedA === processedB;
  };

  const marker = (val, answer) => {
    const text = val.text ? val.text : '';
    const trimmedText = text.trim();
    const preProcessedText = trimmedText.replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
    const preProcessedAnswer = answer.trim().replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
    if (preProcessedAnswer === '1') {
      return val;
    }
    if (preProcessedText === preProcessedAnswer) {
      return {
        ...val,
        text: trimmedText,
        status: 'accept',
      };
    }
    return {
      ...val,
      text: trimmedText,
      status: 'reject',
    };
  };

  const markerForLast = (old, val, colIndex) => {
    const ag = answerGroup.filter((x) => (colIndex >= x.start && colIndex <= x.end))[0];
    if (ag === undefined) {
      return val;
    }
    const text = val.text ? val.text : '';
    const trimmedText = text.trim();

    let i;
    for (i = ag.start; i <= ag.end; i += 1) {
      if (isMatch(val.text, answers[i][colLenStr])) {
        break;
      }
    }

    if (i > ag.end) {
      return {
        ...val,
        text: trimmedText,
        status: 'reject',
      };
    }
    const answerCol = i;

    for (i = ag.start; i < colIndex; i += 1) {
      if (isMatch(val.text, old[i][colLenStr].text)) {
        return {
          ...val,
          text: trimmedText,
          status: 'reject',
        };
      }
    }

    return {
      ...val,
      answerCol,
      text: trimmedText,
      status: 'accept',
    };
  };

  const handleSubmit = () => {
    setData((old) => {
      const newAnswers = solData.map((row, i) => {
        const newRow = {};
        Object.keys(row).forEach((key) => {
          if (answers[i][key] !== '1') {
            newRow[key] = {
              ...solData[i][key],
              status: 'reject',
              text: answers[i][key],
            };
          } else {
            newRow[key] = {
              ...solData[i][key],
            };
          }
        });

        return newRow;
      });

      const newData = old.map((row, i) => {
        const newRow = {};
        Object.keys(row).forEach((key) => {
          if (key === colLenStr && answerGroup) {
            newRow[key] = markerForLast(old, row[key], i);
            if (newRow[key].status === 'accept') {
              newAnswers[newRow[key].answerCol][key] = {
                ...newRow[key],
                text: answers[newRow[key].answerCol][key],
              };
            }
          } else {
            newRow[key] = marker(row[key], answers[i][key]);
            newAnswers[i][key] = {
              ...newRow[key],
              text: answers[i][key],
            };
          }
        });

        return newRow;
      });

      setSolData(() => (newAnswers));
      return newData;
    });
  };

  const handleRemoveAnswers = () => {
    setData((old) => (
      old.map((row) => {
        const newRow = {};
        Object.keys(row).forEach((key) => {
          newRow[key] = {
            ...row[key],
            status: 'none',
          };
        });

        return newRow;
      })));

    setSolData((old) => (
      old.map((row) => {
        const newRow = {};

        Object.keys(row).forEach((key) => {
          newRow[key] = {
            ...row[key],
            text: '???',
            status: 'none',
          };
        });

        return newRow;
      })));
  };

  const handleResetAll = () => {
    setData((old) => (
      old.map((row) => {
        const newRow = {};
        Object.keys(row).forEach((key) => {
          newRow[key] = {
            ...row[key],
            text: '',
            status: 'none',
          };
        });

        return newRow;
      })));

    setSolData((old) => (
      old.map((row) => {
        const newRow = {};

        Object.keys(row).forEach((key) => {
          newRow[key] = {
            ...row[key],
            text: '???',
            status: 'none',
          };
        });

        return newRow;
      })));
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" justify="flex-end">
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          onClick={handleSubmit}
        >
          제출
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="default"
          size="medium"
          type="submit"
          onClick={handleRemoveAnswers}
        >
          답 가리기
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          size="medium"
          type="submit"
          onClick={handleResetAll}
        >
          모두 지우기
        </Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InbodyTableWithRT
            columns={columns}
            data={data}
            rowSpan={rowSpan}
            isBlank={isBlank}
            meta={meta}
            updateMyData={updateMyData}
            defaultCell={EditableCell}
          />
        </Grid>
        <Grid item xs={6}>
          <InbodyTableWithRT
            columns={columns}
            data={solData}
            rowSpan={rowSpan}
            isBlank={isBlank}
            meta={meta}
            updateMyData={() => {}}
            defaultCell={defaultCell}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InbodyTable;
