import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../components/Copyright';
import { useUser } from 'mogo/context/UserContext';
import { Redirect } from 'react-router-dom';
import { updatePassword } from '../services/api';
import { useUserUpdate } from 'mogo/context/UserContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const isValidPasswordForm = (str) => {
  if (str.length !== 4) {
    return false;
  }
  if (/^-?\d+$/.test(str) === false) {
    return false;
  }
  return true;
};

const MogoNewPWForm = () => {
  const user = useUser();
  const setUser = useUserUpdate();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();
  const submitHandler = () => {
    if (
      !isValidPasswordForm(oldPassword) ||
      !isValidPasswordForm(newPassword) ||
      !isValidPasswordForm(newPassword2)
    ) {
      setErrorMessage('4자리 정수가 아닌 값이 입력되었습니다.');
      return;
    }
    if (newPassword !== newPassword2) {
      setErrorMessage('신규 비밀번호와 비밀번호 확인값이 일치하지 않습니다.');
      return;
    }
    if (oldPassword === newPassword) {
      setErrorMessage('기존 비밀번호와 새 비밀번호가 같습니다.');
      return;
    }
    if (newPassword === '0000') {
      setErrorMessage('새 비밀번호는 0000으로 지정할 수 없습니다.');
      return;
    }
    updatePassword(oldPassword, newPassword, newPassword2)
      .then((res) => {
        setUser({
          ...user,
          ...res.data,
        });

        window.location.href = '/mogo/index';
      })
      .catch((err) => {
        setErrorMessage('비밀번호 변경에 실패했습니다.');
      });
  };

  if (!user.isLoggedIn) {
    return <Redirect to="/mogo/login" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          비밀번호 변경
          <br />
          (4자리 숫자로 바꿔주세요. 0000 제외)
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="기존 비밀번호"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="신규 비밀번호"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="신규 비밀번호 확인"
            name="password2"
            type="password"
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={submitHandler}
          >
            확인
          </Button>
        </div>
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default MogoNewPWForm;
