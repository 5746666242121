import { useState } from 'react';

let index = 0;

export default (initialValue) => {
  const [length, setLength] = useState(0);
  const [items, setItems] = useState(initialValue);

  return {
    items,
    addItem: (newText) => {
      setLength(length + 1);
      index += 1;
      setItems([
        ...items,
        {
          id: length,
          status: 'none',
          text: newText.trim(),
        },
      ]);
    },
    saveAndJudge: (newText, answers) => {
      let status;
      const k = items.length;

      if (k >= answers.length) {
        status = 'reject';
      } else if (newText.replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '')
        === answers[k].replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '')) {
        status = 'accept';
      } else {
        status = 'reject';
      }

      setLength(length + 1);
      setItems([
        ...items,
        {
          id: length,
          status,
          text: newText.trim(),
        },
      ]);
    },
    deleteItem: (idToDelete) => {
      const newItems = items.filter((item) => item.id !== idToDelete);

      setItems(newItems);
    },
    deleteAll: () => {
      setItems([]);
    },
    judgeAll: (answers) => {
      let remainAnswers = answers;
      const newItems = items.map((item) => {
        const text = item.text.replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
        let status = 'reject';

        remainAnswers = remainAnswers.filter((answer) => {
          const trimmedAnswer = answer.replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');

          if (status === 'reject' && trimmedAnswer === text) {
            status = 'accept';
            return false;
          }

          return true;
        });

        return { ...item, status };
      });

      remainAnswers = remainAnswers.map((answer, i) => ({
        id: length + i,
        status: 'remain',
        text: answer,
      }));

      setLength(length + remainAnswers.length);
      setItems([...newItems, ...remainAnswers]);
    },
    judgeOrdered: (answers) => {
      let remainAnswers = answers;

      const newItems = items.map((item, id) => {
        const text = item.text.replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
        const trimmedAnswer = answers[id].replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
        const status = trimmedAnswer === text ? 'accept' : 'reject';

        return { ...item, status };
      });

      remainAnswers = remainAnswers.map((answer, i) => ({
        id: length + i,
        status: 'remain',
        text: answer,
      }));

      setLength(length + remainAnswers.length);
      setItems([...newItems, ...remainAnswers]);
    },
  };
};
