import { getProfile } from '../../services/api';

const refreshProfile = (setUser) => {
  getProfile()
    .then((res) => {
      if (res.data?.serialId) {
        setUser(() => ({
          isLoggedIn: true,
          ...res.data,
        }));
      } else {
        setUser(() => ({
          isLoggedIn: false,
        }));
      }
    })
    .catch(() => {
      setUser(() => ({ isLoggedIn: false }));
    });
};

export { refreshProfile };
