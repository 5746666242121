/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import { lightGreen, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  none: {
  },
  accept: {
    backgroundColor: lightGreen[100],
  },
  reject: {
    // backgroundColor: red[100],
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[300],
    border: '2px solid black',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
  },
  body: {
    padding: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
    border: '2px solid black',
  },
}))(TableCell);


function AchieveStandardTable({
  columns, data, updateMyData, defaultCell, secondCell,
}) {
  const classes = useStyles();
  const defaultColumn = {
    Cell: defaultCell,
    Name: secondCell,
  };
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    defaultColumn,
    updateMyData,
  });

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledTableCell {...column.getHeaderProps()}>{column.render('Header')}</StyledTableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                if (cell.value === undefined) {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={j} />
                  );
                }

                let bgColor = '';
                if (j === 0 && row.cells[1].value.status) {
                  bgColor = red[Math.min(row.cells[1].value.cnt * 100, 900)];
                }
                return (
                  <StyledTableCell
                    className={classes[cell.value.status]}
                    style={{
                      width: j === 0 ? '20%' : '80%',
                      backgroundColor: bgColor,
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.value.raw.text !== ''
                      ? (j === 0
                        ? (`${cell.value.raw.text}${row.cells[1].value.cnt ? `<${row.cells[1].value.cnt}>` : ''}`)
                        : cell.render('Cell'))
                      : (j === 0
                          && (`${row.cells[1].value.cnt ? `<${row.cells[1].value.cnt}>` : ''}`)
                      )}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default AchieveStandardTable;
