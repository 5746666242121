import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1.5,
    color: theme.palette.text.secondary,
  },
  none: { backgroundColor: 'transparent' },
  accept: { backgroundColor: lightGreen[100] },
  reject: { backgroundColor: red[100] },
  remain: { backgroundColor: yellow[100], color: yellow[100] },
  text: { fontWeight: 700 },
}));

const ItemList = ({ items, deleteItem }) => {
  const classes = useStyles();

  return (
    <List>
      {items.map((item) => (
        <ListItem key={item.id} className={classes[item.status]} dense button>
          <ListItemText primary={item.text} primaryTypographyProps={{ className: classes.text }} />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Delete"
              onClick={() => {
                deleteItem(item.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default ItemList;
