import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(7),
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1.5,
    color: theme.palette.text.secondary,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const ListJudgeView = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={2}>
      <Typography variant="h4" gutterBottom>
        안녕하세요!
      </Typography>

      <Typography variant="body1">
        임고파워 운영자입니다. 오타 및 자료 추가 문의사항은 imgopower2020@gmail.com 으로 보내주세요.
      </Typography>

      <Typography variant="body1">
        임고파워는 여러분들의 성공적인 임고 마무리를 기원합니다.
      </Typography>

      <Typography variant="body1" gutterBottom>
        항상 감사합니다.
      </Typography>
    </Paper>
  );
};

export default ListJudgeView;
