import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    marginBottom: theme.spacing(2),
  },
  input: {
    width: 50,
  },
}));

export default function RateSlider({ rate, setRate, lastYearRate }) {
  const classes = useStyles();

  const handleSliderChange = (event, newValue) => {
    setRate(newValue);
  };

  const handleInputChange = (event) => {
    setRate(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (rate < 0) {
      setRate(0);
    } else if (rate > 10) {
      setRate(10);
    }
  };

  return (
    <div className={classes.root}>
      <Typography id="input-slider" gutterBottom>
        경쟁률
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof rate === 'number' ? rate : 0}
            onChange={handleSliderChange}
            marks={[
              {
                value: lastYearRate,
                label: `작년 경쟁률: ${lastYearRate}`,
              },
            ]}
            step={0.01}
            min={0.0}
            max={10.0}
            aria-labelledby="input-slider"
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={rate}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 0.01,
              min: 0.0,
              max: 10.0,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
        <Typography variant="h5">: 1 일 경우,</Typography>
      </Grid>
    </div>
  );
}
