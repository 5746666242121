import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';
import Clock from './Clock';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

const numOfItemsPerRow = 10;

export default function Header(props) {
  const classes = useStyles();
  const { sections, title } = props;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={2} />
        <Grid item>
          <Button size="small">
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href="?name=announcement"
              className={classes.toolbarLink}
            >
              <img
                src="/imgopower.png"
                alt="imgopower"
                height="100"
                width="100"
              />
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.toolbarTitle}
              >
                {title}
              </Typography>
              <Clock />
            </Link>
          </Button>
        </Grid>
        <Grid item>
          <Button size="small">
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href="/mogo/index"
              className={classes.toolbarLink}
            >
              <img
                src="/imgomogo.png"
                alt="imgomogo"
                height="120"
                width="240"
              />
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.toolbarTitle}
              >
                배수예측시스템
              </Typography>
            </Link>
          </Button>
        </Grid>
      </Grid>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        {sections.slice(0, 11).map((section, id) => (
          <React.Fragment key={section.name}>
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href={`?name=${section.name}`}
              className={classes.toolbarLink}
            >
              {section.title}
            </Link>
          </React.Fragment>
        ))}
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        {sections.slice(11, 16).map((section, id) => (
          <React.Fragment key={section.name}>
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href={`?name=${section.name}`}
              className={classes.toolbarLink}
            >
              {section.title}
            </Link>
          </React.Fragment>
        ))}
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        {sections.slice(16, 22).map((section, id) => (
          <React.Fragment key={section.name}>
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href={`?name=${section.name}`}
              className={classes.toolbarLink}
            >
              {section.title}
            </Link>
          </React.Fragment>
        ))}
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        {sections.slice(22, 28).map((section, id) => (
          <React.Fragment key={section.name}>
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href={`?name=${section.name}`}
              className={classes.toolbarLink}
            >
              {section.title}
            </Link>
          </React.Fragment>
        ))}
      </Toolbar>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        {sections.slice(28).map((section, id) => (
          <React.Fragment key={section.name}>
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href={`?name=${section.name}`}
              className={classes.toolbarLink}
            >
              {section.title}
            </Link>
          </React.Fragment>
        ))}
      </Toolbar>
    </>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
