import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router-dom';
import { useUser, useUserUpdate } from '../context/UserContext';
import { updateScore } from '../services/api';
import Template from '../components/Template';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

function createData(
  id,
  name,
  scoreA,
  perfectA,
  scoreB,
  perfectB,
  region,
  inputEnd,
  openDate,
) {
  return {
    id,
    name,
    scoreA,
    perfectA,
    scoreB,
    perfectB,
    region,
    inputEnd,
    openDate,
  };
}

const createRows = (user) => [
  createData(
    0,
    '임고파워 모의고사 1회',
    user.scores?.[0]?.scoreA,
    41,
    user.scores?.[0]?.scoreB,
    39,
    user.scores?.[0]?.region,
    '2021-09-26',
    '2021-09-27',
  ),
  createData(
    1,
    '임고파워 모의고사 2회',
    user.scores?.[1]?.scoreA,
    41,
    user.scores?.[1]?.scoreB,
    39,
    user.scores?.[1]?.region,
    '2021-10-03',
    '2021-10-04',
  ),
  createData(
    2,
    '임고파워 모의고사 3회',
    user.scores?.[2]?.scoreA,
    41,
    user.scores?.[2]?.scoreB,
    39,
    user.scores?.[2]?.region,
    '2021-10-17',
    '2021-10-18',
  ),
];

const useStyles = makeStyles((theme) => ({
  tableHead: {
    fontSize: 16,
    fontWeight: 600,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  inputTitleLabel: {
    fontWeight: 600,
    display: 'block',
    color: theme.palette.text.primary,
    margin: 0,
    width: '23%',
    textAlign: 'right',
    '& span': {
      fontWeight: 600,
    },
  },
  scoreInput: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 2),
    width: '30%',
    '& input[type=text], textarea': {
      borderWidth: '0',
      textAlign: 'right',
    },
    '& input[type=text]::-ms-clear': {
      display: 'none',
    },
  },
}));

const MogoTable = ({ modalOn }) => {
  const user = useUser();

  const classes = useStyles();
  const rows = createRows(user);

  if (!user.isLoggedIn) {
    return <Redirect to="/mogo/login" />;
  }
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} align="center">
              모의고사 명
            </TableCell>
            <TableCell className={classes.tableHead} align="right">
              A형 점수
            </TableCell>
            <TableCell className={classes.tableHead} align="right">
              B형 점수
            </TableCell>
            <TableCell className={classes.tableHead} align="right">
              합산 점수
            </TableCell>
            <TableCell className={classes.tableHead} align="center">
              선택 지역
            </TableCell>
            <TableCell className={classes.tableHead} align="center">
              점수 입력
            </TableCell>
            <TableCell className={classes.tableHead} align="center">
              결과지 보기
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="right">
                {row.scoreA ?? '-'} / {row.perfectA ?? '-'}
              </TableCell>
              <TableCell align="right">
                {row.scoreB ?? '-'} / {row.perfectB ?? '-'}
              </TableCell>
              <TableCell align="right">
                {(row.scoreA ?? 0) + (row.scoreB ?? 0)} / 80
              </TableCell>
              <TableCell align="center">{row.region ?? '-'}</TableCell>
              <TableCell align="center">
                {index < 3 ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled
                    onClick={() => modalOn(row.id)}
                  >
                    {row.inputEnd} 까지 점수 입력
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => modalOn(row.id)}
                  >
                    {row.inputEnd} 까지 점수 입력
                  </Button>
                )}
              </TableCell>
              <TableCell align="center">
                {index < 3 ? (
                  <Button type="submit" variant="contained" color="primary">
                    <Link
                      color="white"
                      component={RouterLink}
                      to={`/mogo/result/${index}`}
                    >
                      {row.openDate} 에 오픈됨
                    </Link>
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled
                  >
                    <Link component={RouterLink} to={`/mogo/result/${index}`}>
                      {row.openDate} 에 오픈예정
                    </Link>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

const MogoWrappedTable = (modalOn) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <MogoTable modalOn={modalOn} />
        </Paper>
      </Grid>
    </Grid>
  );
};

const handleChangeScore = (e, score, setScore) => {
  const newScore = e.target.value;
  if (e.target.name === 'scoreA') {
    setScore({ a: newScore, b: score.b });
  } else if (e.target.name === 'scoreB') {
    setScore({ a: score.a, b: newScore });
  }
};

const handleChangeRegion = (e, setRegion) => {
  setRegion(e.target.value);
};

const ScoreDialog = ({ modalClose, testId }) => {
  const classes = useStyles();
  const user = useUser();
  const setUser = useUserUpdate();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [score, setScore] = React.useState(() => ({
    a: user.scores?.[testId]?.scoreA ?? 0,
    b: user.scores?.[testId]?.scoreB ?? 0,
  }));
  const [region, setRegion] = React.useState(
    () => user.scores?.[testId]?.region ?? '세종',
  );
  const submitHandler = () => {
    if (isNaN(parseInt(score.a))) {
      setErrorMessage('A형 점수가 숫자가 아닙니다.');
      return;
    }
    if (isNaN(parseInt(score.b))) {
      setErrorMessage('B형 점수가 숫자가 아닙니다.');
      return;
    }
    if (score.a < 0 || score.a > 41) {
      setErrorMessage('A형 - 점수는 0점 이상 만점 이하여야 합니다.');
      return;
    }
    if (score.b < 0 || score.b > 39) {
      setErrorMessage('B형 - 점수는 0점 이상 만점 이하여야 합니다.');
      return;
    }
    updateScore(testId, parseInt(score.a), parseInt(score.b), region)
      .then((res) => {
        setUser({
          ...user,
          ...res.data,
        });

        modalClose();
      })
      .catch((err) => {
        setErrorMessage(err.error);
      });
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DialogTitle>임고파워 모의고사 {testId + 1}회 점수 입력</DialogTitle>
        <IconButton onClick={modalClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <div>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <FormLabel className={classes.inputTitleLabel}>A형 점수</FormLabel>
            <TextField
              name="scoreA"
              className={classes.scoreInput}
              value={score.a}
              onChange={(e) => handleChangeScore(e, score, setScore)}
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"> / 41점</InputAdornment>
                ),
              }}
            />
          </Grid>
        </div>
        <div>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <FormLabel className={classes.inputTitleLabel}>B형 점수</FormLabel>
            <TextField
              name="scoreB"
              className={classes.scoreInput}
              value={score.b}
              onChange={(e) => handleChangeScore(e, score, setScore)}
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"> / 39점</InputAdornment>
                ),
              }}
            />
          </Grid>
        </div>
        <div>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <FormLabel className={classes.inputTitleLabel}>지역 선택</FormLabel>
            <FormControl variant="outlined" className={classes.scoreInput}>
              <InputLabel id="region-select">지역</InputLabel>
              <Select
                labelId="region-select-label"
                id="region-select"
                value={region}
                onChange={(e) => handleChangeRegion(e, setRegion)}
                label="지역"
              >
                <MenuItem value={'서울'}>서울</MenuItem>
                <MenuItem value={'인천'}>인천</MenuItem>
                <MenuItem value={'경기'}>경기</MenuItem>
                <MenuItem value={'경기연천'}>경기연천</MenuItem>
                <MenuItem value={'경기포천'}>경기포천</MenuItem>
                <MenuItem value={'대전'}>대전</MenuItem>
                <MenuItem value={'세종'}>세종</MenuItem>
                <MenuItem value={'충북'}>충북</MenuItem>
                <MenuItem value={'충남'}>충남</MenuItem>
                <MenuItem value={'충남서부'}>충남서부</MenuItem>
                <MenuItem value={'부산'}>부산</MenuItem>
                <MenuItem value={'울산'}>울산</MenuItem>
                <MenuItem value={'대구'}>대구</MenuItem>
                <MenuItem value={'경북'}>경북</MenuItem>
                <MenuItem value={'경남'}>경남</MenuItem>
                <MenuItem value={'광주'}>광주</MenuItem>
                <MenuItem value={'전북'}>전북</MenuItem>
                <MenuItem value={'전남'}>전남</MenuItem>
                <MenuItem value={'도교지역'}>도교지역</MenuItem>
                <MenuItem value={'강원'}>강원</MenuItem>
                <MenuItem value={'제주'}>제주</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </div>
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={submitHandler}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MogoIndex = () => {
  const [modalInfo, setModalInfo] = React.useState(() => ({
    testId: null,
    isLaunched: false,
  }));
  const modalClose = () =>
    setModalInfo(() => ({
      testId: null,
      isLaunched: false,
    }));
  const modalOn = (testId) =>
    setModalInfo(() => ({
      testId,
      isLaunched: true,
    }));

  return (
    <>
      {modalInfo.isLaunched && (
        <ScoreDialog testId={modalInfo.testId} modalClose={modalClose} />
      )}
      {Template(() => MogoWrappedTable(modalOn))()}
    </>
  );
};

export default MogoIndex;
