import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import useInputState from './useInputState';

const TodoForm = ({
  answers, saveItem, saveAndJudge, isDirectJudge = false,
}) => {
  const { value, reset, onChange } = useInputState('');

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const trimmedText = value.trim();

        if (trimmedText.length <= 0) {
          return;
        }

        if (isDirectJudge) {
          saveAndJudge(trimmedText, answers);
        } else {
          saveItem(trimmedText);
        }
        reset();
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="이곳에 정답 입력"
        margin="normal"
        onChange={onChange}
        value={value}
      />
    </form>
  );
};

TodoForm.defaultProps = {
  saveAndJudge: () => {},
  isDirectJudge: false,
};

TodoForm.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  saveItem: PropTypes.func.isRequired,
  saveAndJudge: PropTypes.func,
  isDirectJudge: PropTypes.bool,
};
export default TodoForm;
