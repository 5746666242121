import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Header from 'components/Header';
import Main from 'components/Main';
import './App.css';

const sections = require('../data/sections.json');

function Home({ location }) {
  const query = queryString.parse(location.search);
  const section = sections.filter((item) => item.name === query.name)[0];
  let name = section === undefined ? 'TotalLoan' : section.name;
  let title = section === undefined ? '총론' : section.title;
  if (query.name === 'announcement') {
    name = 'announcement';
    title = 'announcement';
  }

  return (
    <Container maxWidth="xl">
      <Header title="ImgoPower" sections={sections} />
      <Main name={name} title={title} />
    </Container>
  );
}

export default Home;
