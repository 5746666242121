import React from 'react';
import { InputBase, makeStyles } from '@material-ui/core';
import { lightGreen, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  none: {
  },
  accept: {
    backgroundColor: lightGreen[100],
  },
  reject: {
    // backgroundColor: red[100],
  },
}));

const EditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  cursor,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue.text);
  const classes = useStyles();
  const textareaRef = React.useRef(null);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  const onFocus = () => {
    console.log(cursor);
    console.log(index);
    console.log(id);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue.text);
  }, [initialValue]);

  return (
    <textarea
      className={classes[value.status]}
      ref={textareaRef}
      rows={2}
      style={{
        padding: 0,
        margin: 0,
        border: 0,
        fontSize: initialValue.meta.isBold ? 20 : 16,
        fontWeight: initialValue.meta.isBold ? 700 : 500,
        width: '100%',
        height: '100%',
        resize: 'none',
      }}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={initialValue.meta.placeholder || '이곳에 정답 입력'}
    />
  );
};

export default EditableCell;
