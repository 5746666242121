import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home, About } from 'pages';
import MogoLogin from '../mogo/pages/MogoLogin';
import MogoNewPWForm from '../mogo/pages/MogoNewPWForm';
import MogoIndex from '../mogo/pages/MogoIndex';
import MogoResult from '../mogo/pages/MogoResult';
import { UserProvider } from '../mogo/context/UserContext';

const App = () => {
  return (
    <UserProvider>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/mogo/login" component={MogoLogin} />
        <Route exact path="/mogo/pw" component={MogoNewPWForm} />
        <Route exact path="/mogo/index" component={MogoIndex} />
        <Route exact path="/mogo/result/:testId" component={MogoResult} />
      </Switch>
    </UserProvider>
  );
};

export default App;
