import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import ItemList from './ItemList';
import ItemAdder from './ItemAdder';
import useListState from './useListState';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1.5,
    color: theme.palette.text.secondary,
  },
  chunking: {
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1,
    color: theme.palette.text.primary,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const ListJudgeView = ({ subject, answers, isDirectJudge }) => {
  const classes = useStyles();
  const {
    items, addItem, deleteItem, judgeAll, saveAndJudge, deleteAll,
  } = useListState([]);

  return (
    <Paper className={classes.paper} elevation={3}>
      <Typography variant="h4" gutterBottom>
        {subject}
      </Typography>

      <TextField
        fullWidth
        multiline
        rowsMax="4"
        color="primary"
        variant="outlined"
        placeholder="이곳에 청킹 입력"
        size="small"
        margin="dense"
      />

      <ItemAdder
        answers={answers}
        saveItem={addItem}
        saveAndJudge={saveAndJudge}
        isDirectJudge={isDirectJudge}
      />

      <ItemList items={items} deleteItem={deleteItem} />

      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => {
            judgeAll(answers);
          }}
        >
          제출
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          onClick={() => {
            deleteAll(answers);
          }}
        >
          모두 지우기
        </Button>
      </div>
    </Paper>
  );
};

export default ListJudgeView;
