import axios from 'axios';

const doLogin = (serialId, password) =>
  axios.post('/api/login', { serialId, password }, { withCredentials: true });
const getProfile = () => axios.get('/api/profile', {});
const doLogout = () => axios.get('/api/logout', {});
const updateScore = (testId, scoreA, scoreB, region) =>
  axios.post(
    '/api/score',
    { testId, scoreA, scoreB, region },
    { withCredentials: true },
  );
const updatePassword = (oldPassword, newPassword, newPassword2) =>
  axios.post(
    '/api/pw',
    { oldPassword, newPassword, newPassword2 },
    { withCredentials: true },
  );
const getResult = (testId) => axios.get(`/api/result/${testId}`);

export {
  doLogin,
  doLogout,
  updateScore,
  getProfile,
  updatePassword,
  getResult,
};
