import React from 'react';
import {
  Grid, TextField, Typography, Button, makeStyles,
} from '@material-ui/core';

import AchieveStandardTable from './AchieveStandardTable';
import EditableCell from './EditableCell';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const makeAnswers = (body) => {
  const data = body.map((row, i) => {
    const dataRow = {};
    row.forEach((col, j) => {
      const p = j.toString();
      if (col.text !== undefined) {
        dataRow[p] = col;
      }
    });

    return dataRow;
  });

  return data;
};

const AchieveStandard = ({ table }) => {
  const classes = useStyles();
  const columns = React.useMemo(() => table.head, []);
  const answers = React.useMemo(() => makeAnswers(table.body), []);
  const [data, setData] = React.useState(() => answers.map(
    (row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = {
          text: '', status: 'none', raw: row[key], cnt: 0,
        };
      });

      return newRow;
    },
  ));
  const [solData, setSolData] = React.useState(() => answers.map(
    (row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = { text: '???', status: 'none', raw: row[key] };
      });

      return newRow;
    },
  ));

  const handleSubmit = () => {
    setSolData((old) => (old.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = {
          ...row[key],
          text: row[key].raw.text,
        };
      });

      return newRow;
    })));
  };

  const handleRemoveAnswers = () => {
    setSolData((old) => (old.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = {
          ...row[key],
          text: '???',
        };
      });

      return newRow;
    })));
  };

  const handleResetAll = () => {
    setData((old) => (
      old.map((row) => {
        const newRow = {};
        Object.keys(row).forEach((key) => {
          newRow[key] = {
            ...row[key],
            text: '',
            status: 'none',
            cnt: 0,
          };
        });

        return newRow;
      })));

    setSolData((old) => (
      old.map((row) => {
        const newRow = {};

        Object.keys(row).forEach((key) => {
          newRow[key] = {
            ...row[key],
            text: '???',
          };
        });

        return newRow;
      })));
  };
  const defaultCell = ({ cell }) => (
    <textarea
      disabled
      style={{
        padding: 0,
        margin: 0,
        border: 0,
        fontSize: 16,
        // fontSize: cell.value.meta.isBold ? 20 : 16,
        fontWeight: 600,
        // cell.value.meta.isBold ? 800 : 600,
        width: '100%',
        height: '100%',
        resize: 'none',
      }}
      value={cell.value.text}
    />
  );

  const marker = (val, answer) => {
    const text = val.text ? val.text : '';
    const trimmedText = text.trim();
    const preProcessedText = trimmedText.replace(/,/g, '').replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
    const preProcessedAnswer = answer.trim().replace(/,/g, '').replace(/\s/g, '').replace(/[.]/g, '').replace(/[,]/g, '');
    if (preProcessedAnswer === '1' || text === '') {
      return val;
    }
    if (preProcessedText === preProcessedAnswer) {
      return {
        ...val,
        text: trimmedText,
        status: 'accept',
      };
    }
    return {
      ...val,
      text: trimmedText,
      status: 'reject',
      cnt: val.cnt + 1,
    };
  };

  const updateMyData = (rowIndex, columnId, text) => {
    setData((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: row[columnId].text === text ? row[columnId] : marker({
            ...row[columnId],
            text,
          }, row[columnId].raw.text),
        };
      }
      return row;
    }));
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" justify="space-between" spacing={3}>
        <Grid item>
          <b>* NOTICE *</b>
          <br />
          1. [Tab]을 누르면 밑으로 한 칸 내려갑니다.
          <br />
          {'2. 한 번 틀릴 때마다 왼쪽칸에 <n>번 카운트 됩니다.'}
          <br />
          3. 틀릴 때마다 왼쪽칸은 점점 빨개집니다. 시뻘건것들은 다시 암기!
          <br />
          4. 오타, 피드백은 언제나 환영입니다. 모두가 잘못외우는 최악의 경우가 생기지 않도록 꼭 연락주세요!
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            onClick={handleSubmit}
          >
            답 보기
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="default"
            size="medium"
            type="submit"
            onClick={handleRemoveAnswers}
          >
            답 가리기
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            size="medium"
            type="submit"
            onClick={handleResetAll}
          >
            모두 지우기
          </Button>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <AchieveStandardTable
              columns={columns}
              data={data}
              updateMyData={updateMyData}
              defaultCell={EditableCell}
              secondCell={defaultCell}
            />
          </Grid>
          <Grid item xs={6}>
            <AchieveStandardTable
              columns={columns}
              data={solData}
              updateMyData={() => {}}
              defaultCell={defaultCell}
              secondCell={defaultCell}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AchieveStandard;
