import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ListJudgeView from '../ListJudge/ListJudgeView';
import JudgeLikeExcelView from '../JudgeLikeExcel/JudgeExcelView';
import InbodyTable from '../InbodyTable/InbodyTable';
import AchieveStandard from '../AchieveStandard/AchieveStandard';
import AnnouncementView from '../pages/AnnouncementView';

const useStyles = makeStyles((theme) => ({
}));
const itemsPerRow = 2;
const nuclearModel = ['koreanModel', 'artModel', 'mathModel', 'moralModel', 'moralProcess', 'totalModel', 'PEModel', 'societyModel', 'scienceModel', 'fruitModel'];

export default function Main({ name }) {
  const totalLoan = require(`data/${name}.json`);

  if (name.includes('Inner')) {
    return (
      <InbodyTable table={totalLoan} />
    );
  }
  if (name.includes('Standard')) {
    return (
      <AchieveStandard table={totalLoan} />
    );
  }
  if (name.includes('announcement')) {
    return (
      <AnnouncementView />
    );
  }

  const totalLoanCols = parseInt(totalLoan.length / itemsPerRow, 10) + 1;
  const renderView = (pageInfo) => {
    if (name.includes('TotalLoan')) {
      return (
        <ListJudgeView
          subject={pageInfo.subject}
          answers={pageInfo.answers}
          isDirectJudge={false}
        />
      );
    }
    if (nuclearModel.includes(name)) {
      return (
        <JudgeLikeExcelView
          subject={pageInfo.subject}
          answers={pageInfo.answers}
          actions={pageInfo.actions}
        />
      );
    }

    return (
      <JudgeLikeExcelView
        subject={pageInfo.subject}
        answers={pageInfo.answers}
        actions={pageInfo.answers.map(() => (['']))}
      />
    );
  };

  return (
    <>
      {[...Array(totalLoanCols)].map((_a, rowId) => {
        const end = Math.min((rowId + 1) * itemsPerRow, totalLoan.length);

        return (
          <Grid key={rowId} container spacing={itemsPerRow}>
            {totalLoan.slice(rowId * itemsPerRow, end).map((_b, id) => (
              <Grid key={totalLoan[rowId * itemsPerRow + id].subject} item xs={12 / itemsPerRow}>
                {renderView(totalLoan[rowId * itemsPerRow + id])}

                {/* <ListJudgeView
                  subject={totalLoan[rowId * itemsPerRow + id].subject}
                  answers={totalLoan[rowId * itemsPerRow + id].answers}
                  isDirectJudge={name !== 'TotalLoan'}
                /> */}
              </Grid>
            ))}
          </Grid>
        );
      })}
    </>
  );
}
